import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ArrivalsPage from './pages/ArrivalsPage';
import DeparturesPage from './pages/DeparturesPage';
import "./components/trip.css";

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/arrivals" element={<ArrivalsPage />} />
          <Route path="/departures" element={<DeparturesPage />} />
          <Route path="/departures/passenger" element={<DeparturesPage />} />
          <Route path="/" element={
            <div className='p-5'>
              <h1 className='text-center'>Welcome to the Flight Information System</h1>
              <button className='btn btn-primary'> <Link className='text-white' to="arrivals">Arrivals</Link></button>
              <button className='btn btn-primary ml-3 !important'> <Link className='text-white' to="departures">Departures</Link></button>
              <button className='btn btn-info ml-3 !important'> <Link className='text-white' to="departures/passenger">Departures(Passenger)</Link></button>
            </div>}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
