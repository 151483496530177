export enum RemarkCode {
    OPEN = "CDK",
    OPEN_CKS = "CKS",
    STAND_F01 = "STD",
    NO_OPE = "OPE",
    CANCELLED = "FCL",
    DELAYED = "FDL",
    DEPARTED = "FDP",
    LANDED = "FLD",
    CLOSED_CCL = "CCL",
    FIRST_BAG = "CFB",
    LAST_BAG = "CLB",
    BAG_DELAY = "CDL",
    OPEN_COP = "COP",
    GATE_OPEN = "GOP",
    GATE_CLOSED = "GCL",
    BOARDING = "GBD",
    FINAL_CALL = "GFC",
    NEW_GATE = "GCH",
    OPEN_DOP = "DOP",
    REOPEN = "DRO",
    CLOSED_DCL = "DCL",
    RECLOSE = "DRC",
    OPEN_IOP = "IOP",
    CLOSED_ICL = "ICL",
    CONFIRMED = "FCF",
    INQUIRE = "FIN",
    DIVERTED = "FDV",
    RETIME = "FRT",
    DELAYED_CAR = "CAR",
    AT_LCCT_LCC = "LCC",
    AT_LCCT_ICC = "ICC",
    NEXT_FLIGHT = "NXT",
    NEXT_FLIGHT_NXF = "NXF",
    USE_KIOSK = "KSK",
    RETURN_FLIGHT = "FRB",
    TESTING = "TST",
    NEW_TIME = "NTM",
    GO_TO_GATE = "GTG",
    GATE_SHOWN = "GSH",
    GATE_CHANGE = "GCN"
}
