import { RemarkCode } from "./remark.code";

export const getDisplayColor = (remarkCodeKey: string) => {
    let result = "";
    switch (remarkCodeKey) {
        case RemarkCode.DELAYED:
        case RemarkCode.CANCELLED:
        case RemarkCode.GATE_CLOSED:
            result = "text-red";
            break;
        case RemarkCode.FINAL_CALL:
        case RemarkCode.LAST_BAG:
            result = "text-warning";
            break;

        case RemarkCode.DEPARTED:
        case RemarkCode.LANDED:
            result = "text-done";
            break;
        case RemarkCode.GATE_OPEN:
        case RemarkCode.OPEN:
        case RemarkCode.OPEN_COP:
            result = "text-pre";
            break;
        default:
            result = "text-defualt";
            break;
    };
    return result;
};