export const Cities: any = {
    'CTS': 'Chitose',
    'TSE': 'Astana',
    'HYD': 'Hyderabad',
    'DMM': 'Dammam',
    'HND': 'Haneda',
    'CEH': 'Springfield',
    'TIP': 'Tripoli',
    'PVG': 'Pudong',
    'AMI': 'Mataram',
    'RUN': 'Reunion',
    'ICN': 'Seoul - Incheon',
    'WAW': 'Warsaw',
    'GYD': 'Azerbaijan',
    'LGW': 'London- Gatwick',
    'VKO': 'Moscow',
    'DNA': 'Okinawa City',
    'TLS': 'Toulouse- France',
    'PRG': 'Czech Republic',
    'OKO': 'Fussa- Tokyo',
    'LHW': 'Lanzhou',
    'TRZ': 'Tiruchirapalli',
    'DMK': 'Don Mueang',
    'MMX': 'Sweden',
    'IKA': 'Isfahan',
    'SEZ': 'Seychells',
    'AUH': 'Abu Dhabi',
    'GES': 'General Santos',
    'ADE': 'Yemen',
    'OEY': 'Saudi Arabia',
    'INC': 'Yinchuan',
    'ATQ': 'Amritsar',
    'KRW': 'Turkmenbashi',
    'ASM': 'Ethiopia',
    'KBV': 'Krabi',
    'RCH': 'Okinawa',
    'OOL': 'Gold Coast',
    'SLL': 'Salalah- Oman',
    'ADL': 'Adelaide',
    'NAN': 'Nadi Fiji',
    'WDH': 'Windhoek',
    'TAO': 'Qing Dao',
    'TNA': 'Jinan',
    'SDA': 'Baghdad',
    'HKG': 'Hong Kong',
    'DVO': 'Davao',
    'HLP': 'HLM Perdana',
    'REP': 'Siem Reap',
    'XIY': "Xi'an",
    'XCH': 'Christmas Island',
    'FJR': 'Fujairah',
    'SAH': 'Sanaa',
    'BME': 'Broome',
    'JAI': 'Jaipur',
    'DLC': 'Dalian',
    'RMI': 'Rimini',
    'RBA': 'Morocco',
    'MWA': 'Midwest',
    'PEW': 'Peshawar',
    'XNN': 'Caojiabao',
    'VCV': 'Victorville',
    'LXR': 'Egypt',
    'BCH': 'East Timor',
    'FNJ': 'Pyongyang',
    'QPG': 'Payalebar',
    'CCU': 'Kolkata',
    'YIA': 'Yogyakarta',
    'SAI': 'Siem Reap-Angkor',
    'TFU': 'Jianyang',
    'MDC': 'Menado',
    'BTS': 'Bratislava',
    'ATH': 'Athens',
    'JBG': 'Guam',
    'HNL': 'Honolulu',
    'KBP': 'Kiev',
    'FRU': 'Bishkek',
    'NKW': 'Diego Garcia',
    'KGY': 'Kyrghyzstan',
    'MOW': 'Moscow',
    'TST': 'Trang, Thai',
    'SPK': 'Sapporo- Japan',
    'CGN': 'Cologne',
    'PKG': 'Pangkor',
    'BAK': 'Azerbaijan',
    'NGB': 'Ningbo',
    'RCM': 'Richmond',
    'TYN': 'Taiyuan',
    'YUL': 'Montreal',
    'DAV': 'David',
    'PAR': 'Paris',
    'SPL': 'Schipol',
    'BTU': 'Bintulu',
    'BQS': 'Blagoveschensk',
    'SKD': 'Samarqand',
    'NOU': 'New Caledonia',
    'HRE': 'Harare',
    'XKY': 'Tanzania',
    'KWL': 'Guilin',
    'UTP': 'Uthaphao Thai',
    'SOC': 'Solo City',
    'VLC': 'Valencia',
    'BPN': 'Balikpapan',
    'ALA': 'Almaty',
    'JOG': 'Yogyakarta',
    'BOH': 'Bournemouth',
    'BDO': 'Bandung',
    'DUS': 'Dusseldorf',
    'JIB': 'Djibouti',
    'MDL': 'Mandalay',
    'TRN': 'Turin',
    'URT': 'Surat Thani',
    'JJN': 'Jinjiang',
    'TSV': 'Townsville',
    'ESB': 'Esenboga',
    'BCN': 'Barcelona',
    'HAS': 'Saudi Arabia',
    'ARN': 'Stockholm',
    'SRG': 'Semarang',
    'MSE': 'Manston',
    'AMD': 'Ahmedabad',
    'CKG': 'Chongqing',
    'XCR': 'Chalons Vatry',
    'TUN': 'Tunis',
    'CSX': 'Changsha',
    'MPM': 'Maputo',
    'ASB': 'Ashgabat',
    'DRW': 'Darwin',
    'KUD': 'Kudat',
    'UDR': 'Udaipur',
    'BSR': 'Basrah',
    'EBL': 'Iraq',
    'DXB': 'Dubai',
    'EZE': 'Buenos Aires',
    'DOH': 'Doha',
    'BRU': 'Brussels',
    'TNN': 'Taiwan',
    'MWX': 'Muan',
    'WLG': 'New Zealand',
    'GAN': 'Gan Island',
    'ORY': 'Paris',
    'CBR': 'Canberra',
    'DAD': 'Danang',
    'HIK': 'Hawai',
    'OAI': 'Afghanistan',
    'OAA': 'Australia',
    'HHA': 'Huanghu China',
    'ABS': 'Egypt',
    'MYT': 'Myitkyina',
    'FAB': 'United Kingdom',
    'WUX': 'Wuxi',
    'BWH': 'Butterworth',
    'BOD': 'France',
    'BGW': 'Baghdad',
    'ROR': 'Pulau Manila',
    'ALG': 'Algiers',
    'ROK': 'Rockhampton',
    'LOP': 'Lombok',
    'MAJ': 'Majuro',
    'JRO': 'Tanzania',
    'KWE': 'Guiyang',
    'KHB': 'Bahrain',
    'KOS': 'Sihanoukville',
    'DSA': 'Sheffield',
    'CXR': 'Nha Trang',
    'WNZ': 'Wenzhou',
    'AMQ': 'Ambon',
    'TJQ': 'Tanjung Pandan',
    'AAN': 'Al Ain',
    'LJG': 'Lijiang',
    'ASP': 'Alice Springs',
    'ILO': 'Cabatuan',
    'KJA': 'Russia',
    'HHQ': 'Hua Hin',
    'OPF': 'Miami',
    'CLT': 'Charlotte',
    'OSN': 'Osan South Korea',
    'MBA': 'Mombasa',
    'BLQ': 'Bologna- Italy',
    'PQC': 'Phu Quoc',
    'DTB': 'Silangit',
    'LGG': 'Wallonia',
    'PGK': 'Pangkal Pinang',
    'WKF': 'Pretoria',
    'TRK': 'Tarakan',
    'BWX': 'Banyu Wangi',
    'VNS': 'Varanasi',
    'VCA': 'Can Tho',
    'ISL': 'Istanbul',
    'BKS': 'Bengkulu',
    'DIA': 'QATAR',
    'TKG': 'Bandar Lampung',
    'YTY': 'Yangzhou',
    'YGJ': 'MIHO',
    'DLI': 'Dalat',
    'PKX': 'BEIJING',
    'FIH': 'REPUBLIC CONGO',
    'SAW': 'Istanbul-Sabiha',
    'DAR': 'Dar Es Salam',
    'BFI': 'Seattle',
    'KNO': 'Kuala Namu',
    'NVI': 'Navoi',
    'ERZ': 'Erzurum',
    'NYT': 'Naypyitaw',
    'LLW': 'Lilongwe',
    'BOJ': 'Bulgaria',
    'VVO': 'Vladisvostok',
    'KKJ': 'Kita Kyushu',
    'ADD': 'Addis Ababa',
    'LUN': 'Lusaka Zambia',
    'FCO': 'Rome',
    'RKT': 'Ras Al Khaimah',
    'XX2': 'Testing Display',
    'KRK': 'Krakow',
    'FRA': 'Frankfurt',
    'MLH': 'Switzerland',
    'AMM': 'Amman',
    'ADJ': 'Jordan',
    'KBL': 'Afghanistan',
    'POS': 'Trinidad',
    'BJS': 'Beijing',
    'LBG': 'France',
    'PFO': 'Paphos, Cyprus',
    'TRV': 'Trivandrum',
    'CPH': 'Copenhagen',
    'SYZ': 'Shiraz',
    'MHD': 'Mashad',
    'KRT': 'Khartoum',
    'MKZ': 'B. Berendam',
    'USM': 'Koh Samui',
    'DYU': 'Tarjikistan',
    'DUB': 'Ireland',
    'CGP': 'Chittagong',
    'KTE': 'K. Trengganu',
    'UAM': 'Guam',
    'SDK': 'Sandakan',
    'SEL': 'Seoul- Incheon',
    'SFO': 'San Francisco',
    'SFS': 'Subic Bay',
    'SGN': 'Ho Chi Minh City',
    'SHA': 'Shanghai',
    'KDT': 'Thailand',
    'LOS': 'Lagos- Nigeria',
    'SIN': 'Singapore',
    'SUB': 'Surabaya',
    'SWA': 'Shantou',
    'SYD': 'Sydney',
    'TAS': 'Tashkent',
    'TGG': 'K. Terengganu',
    'THR': 'Tehran',
    'TOD': 'Pulau Tioman',
    'TPE': 'Taipei',
    'TWU': 'Tawau',
    'UPG': 'Ujung Padang',
    'VIE': 'Vienna',
    'VTE': 'Vientiane',
    'XMN': 'Xiamen',
    'YVR': 'Vancouver',
    'ZAG': 'Zagreb',
    'ZRH': 'Zurich',
    'PNK': 'Pontianak',
    'PUS': 'Busan',
    'JED': 'Jeddah',
    'JHB': 'Johor Bahru',
    'JNB': 'Johannesburg',
    'KBR': 'Kota Bharu',
    'KCH': 'Kuching',
    'KHH': 'Kaohsiung',
    'KHI': 'Karachi',
    'KIX': 'Kansai Osaka',
    'KMG': 'Kunming',
    'KUA': 'Kuantan',
    'KUL': 'Kuala Lumpur',
    'LAX': 'Los Angeles',
    'LBU': 'Labuan',
    'LGK': 'Pulau Langkawi',
    'LHE': 'Lahore',
    'LHR': 'London- Heathrow',
    'MAA': 'Chennai',
    'MAD': 'Madrid',
    'MED': 'Medina',
    'MEL': 'Melbourne',
    'MES': 'Medan',
    'MFM': 'Macau',
    'MLE': 'Male',
    'MNL': 'Manila',
    'MRU': 'Mauritius',
    'MUC': 'Minuch',
    'MYY': 'Miri',
    'NGO': 'Nagoya',
    'NRT': 'Narita',
    'LPQ': 'Luang Prabang',
    'PEK': 'Beijing',
    'PEN': 'Penang',
    'PER': 'Perth',
    'PKU': 'Pekan Baru',
    'PLM': 'Palembang',
    'PNH': 'Phnom Penh',
    'RGN': 'Yangon',
    'SBW': 'Sibu',
    'AKL': 'Auckland',
    'AMS': 'Amsterdam',
    'ANC': 'Anchorage',
    'AOR': 'Alor Setar',
    'BEY': 'Beirut',
    'BKI': 'K. Kinabalu',
    'BKK': 'Bangkok',
    'BNE': 'Brisbane',
    'BTJ': 'Banda Aceh',
    'NHD': 'UAE',
    'MYP': 'Turkmenistan',
    'BWN': 'B. Seri Begawan',
    'CAI': 'Cairo',
    'CAN': 'Guangzhou',
    'CDG': 'Paris',
    'CEB': 'Cebu',
    'CGK': 'Jakarta',
    'CMB': 'Colombo',
    'CNS': 'Cairns',
    'CNX': 'Chiang Mai',
    'CPT': 'Cape Town',
    'DAC': 'Dhaka',
    'DEL': 'New Delhi',
    'DHA': 'Dhahran',
    'DPS': 'Denpasar',
    'CRD': 'Comodoro',
    'ABV': 'Nigeria',
    'OPG': 'Papua New Guinea',
    'LAC': 'P. Layang-Layang',
    'AHA': 'Okinawa',
    'BZV': 'Rep of Congo',
    'RYK': 'Pakistan',
    'LAD': 'Angola',
    'LDU': 'Lahad Datu',
    'FUK': 'Fukuoka',
    'HAN': 'Hanoi',
    'HDY': 'Hatyai',
    'HKT': 'Phuket',
    'XX1': 'Testing Display',
    'IPH': 'Ipoh',
    'IST': 'Istanbul',
    'SPU': 'Split',
    'PDG': 'Padang',
    'SCL': 'Santiago',
    'SHJ': 'Sharjah',
    'GVA': 'Geneve- Cointrin',
    'KWI': 'Kuwait',
    'EWR': 'Newark',
    'ISB': 'Islamabad',
    'RUH': 'RIYADH',
    'TSA': 'Taipei',
    'VTZ': 'Visakhapatnam',
    'AKJ': 'Asahikawa',
    'HKD': 'Hakodate',
    'CJU': 'Jeju',
    'GMP': 'Seoul',
    'CEI': 'Chiang Rai',
    'LPL': 'Liverpool',
    'ULN': 'Ulaabataar',
    'BHH': 'Bisha- Suaudi',
    'DUR': 'South Africa',
    'HOD': 'Hodeidah- Yemen',
    'GOI': 'Goa',
    'MKE': 'Milwaukee',
    'MEX': 'Mexico City',
    'LUX': 'Luxembourg',
    'URC': 'Urumqi',
    'INU': 'Nauru',
    'PNI': 'Pohnpei',
    'GUM': 'Guam Island',
    'ZHA': 'Zhanjiang',
    'POM': 'Port Moresby',
    'BOM': 'Mumbai',
    'SZB': 'Subang',
    'SVO': 'Moscow - SVO',
    'SYX': 'Sanya',
    'CRK': 'Clark Field',
    'CTU': 'Chengdu',
    'HRB': 'Harbin',
    'SZX': 'Shenzhen',
    'HRJ': 'Chuarjhari',
    'SHE': 'Shenyang',
    'DTW': 'Detroit',
    'LGA': 'LA Guardia',
    'AVV': 'Melbourne- AVV',
    'MAN': 'Manchester',
    'BLR': 'Bangalore',
    'HAK': 'Haikou',
    'PHE': 'Port Hedland',
    'TNR': 'Antanarivo',
    'HHN': 'Hann',
    'CGO': 'Zhengzhou',
    'CHC': 'Christchurch',
    'NKG': 'Nanjing',
    'FOC': 'Fuzhou',
    'SPN': 'Saipan',
    'PAE': 'Everette',
    'NBO': 'Nairobi',
    'SEA': 'Seattle (US)',
    'BTH': 'Batam',
    'ZGC': 'Zong Chuan',
    'STN': 'London- Stansted',
    'MTS': 'Swaziland',
    'GYR': 'Goodyear Phoenix',
    'PBH': 'Paro',
    'BBI': 'Bhubaneswar',
    'MXP': 'Malpensa',
    'SEY': 'Seychelles',
    'MCT': 'Muscat',
    'XSP': 'Seletar',
    'MMC': 'Macau',
    'MST': 'Maastricht',
    'JFK': 'J F Kennedy',
    'NNG': 'Nanning',
    'BAH': 'Bahrain',
    'KTM': 'Kathmandu',
    'NST': 'Netherland',
    'HGH': 'Hangzhou',
    'KOJ': 'Kagoshima',
    'RIY': 'Yemen',
    'LDE': 'Lourdes, France',
    'EBB': 'Uganda',
    'HRI': 'Hambantota',
    'PZU': 'Sudan',
    'SAJ': 'Bangladesh',
    'KLO': 'Kalibo',
    'TSN': 'Tianjin',
    'OVB': 'Novosibirsk',
    'OKA': 'Okinawa',
    'DAM': 'Damascus',
    'FAI': 'Fairbanks',
    'COK': 'Cochin',
    'WUH': 'Wuhan',
    'ORD': 'Chicago',
    'OKJ': 'Okayama',
    'MSJ': 'Misawa',
    'IKT': 'Irkutsk',
    'BSL': 'Switzerland',
    'DIL': 'Timor Leste',
    'LBJ': "Labuan Bajo",
    'DSN': 'Ordos',
    'DYG': 'Zhangjiajie',
    'KZN': 'Kazan'
} 