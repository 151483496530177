// src/socket.ts
import { io, Socket } from 'socket.io-client';

const url = process.env.REACT_APP_API_URL.split("://");
const socketURL = `ws${url[0]==="https"?"s":""}://${url[1]}`;
console.log(socketURL)
const socket: Socket = io(socketURL, {
  transports: ['websocket'], // Use websocket transport
  autoConnect: true, // Avoid auto-connecting (can be controlled manually)
});

export default socket;
