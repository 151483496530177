// src/pages/DeparturesPage.tsx

import React, { useEffect, useState } from 'react';
import DepartureComponent from '../components/departure.component';
import moment from 'moment';

const DeparturesPage: React.FC = () => {
  const [updatedAt, setUpdatedAt] = useState<Date>();
  const [currentTime, setCurrentTime] = useState(moment());
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default value, dynamically updated
  const [previousDate, setPreviousDate] = useState(moment().format('YYYY-MM-DD')); // Track previous date
  const [isDateChanges, setIsDateChanges] = useState(false);

  // Function to dynamically calculate rows per page based on viewport height
  const calculateRowsPerPage = () => {
    const viewportHeight = window.innerHeight;
    const footerHeight = 40; // Use desktop or mobile header height
    const headerHeight = window.innerWidth > 768 ? 56 : 93.8; // Use desktop or mobile header height
    const rowHeight = window.innerWidth > 768 ? 42.5 : 38.9; // Use desktop or mobile row height

    const availableHeight = viewportHeight - footerHeight - headerHeight - 43.3; // Adjust for thead height as well
    const newRowsPerPage = Math.floor(availableHeight / rowHeight);
    setRowsPerPage(newRowsPerPage);
  };

  useEffect(() => {
    // Calculate rows per page initially
    calculateRowsPerPage();

    // Recalculate on window resize
    window.addEventListener('resize', calculateRowsPerPage);
    return () => window.removeEventListener('resize', calculateRowsPerPage);
}, []);

  useEffect(() => {
    // Set body background color when component mounts
    document.body.style.backgroundColor = "#071d80"; // Change this to any color you want

    const intervalId = setInterval(() => {
      const now = moment();
      setCurrentTime(now);

      const currentDate = now.format('YYYY-MM-DD');
      if (currentDate !== previousDate) {
        console.log("Date has changed!");
        if(previousDate) setIsDateChanges(true);
        setPreviousDate(currentDate); // Update previous date
      }
    }, 1000);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId);
      // Reset body background color when the component unmounts
      document.body.style.backgroundColor = '';
    };
  }, [previousDate]);

  // Reset `isDateChanges` after notifying ArrivalComponent
  useEffect(() => {
    if (isDateChanges) {
      console.log("Notifying ArrivalComponent of date change...");
      setIsDateChanges(false); // Reset after notifying
    }
  }, [isDateChanges]); // Trigger when `isDateChanges` is updated

  return (
    <div>
      <div className='row m-0 text-white'>
        <div className='col-md-3'><h1 className='ml-2'>Departures</h1></div>
        <div className='col-md-6 t-date'><h5>{moment(currentTime).format('dddd DD MMMM YYYY')} <span className='ml-3'>{moment(currentTime).format('HH:mm')}</span></h5></div>
        <div className='col-md-3 t-date'><h5 className="text-xl">Updated at {moment(updatedAt).format('HH:mm')}</h5></div>
      </div>
      <DepartureComponent isDateChanges={isDateChanges} rowsPerPage={rowsPerPage} updateArrivals={(date) => setUpdatedAt(date)}></DepartureComponent>
    </div>
  );
};

export default DeparturesPage;
